import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';

import useProjects from '../../../queries/projectsQueries';
import CustomCard from '../../CustomProjectCard';

import './allProjects.scss';

const AllProjects = (props) => {
  const { pageContext } = props;
  const projectsData = useProjects();
  const langProjectsData = projectsData.filter(
    (item) => item.locale === props.pageContext.locale,
  );
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="relative">
      <div className="projects-title container-wrapper">
        <h2 className="custom-font-inter">{props.data.title}</h2>
        <p className="project-description custom-font-inter">
          {props.data.description}
        </p>
      </div>
      <div className="main-all-projects-container container-wrapper">
        {langProjectsData.map((item) => (
          <div data-aos="fade-up" data-aos-duration="1000" key={item.id}>
            <CustomCard
              pageContext={pageContext}
              projectKey={item.id}
              image={item.image.url}
              description={item?.shortDescription}
              title={item?.title}
              slug={item.slug}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
AllProjects.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    projectContainer: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default AllProjects;
