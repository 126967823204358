import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { RiArrowRightLine } from 'react-icons/ri';

import CustomLink from '../CustomLink';
import { localizePath } from '../../utils/localize';

import '../Sections/News&Blog/news&Blog.scss';

const NewsBlogCard = (props) => (
  <div>
    <CustomLink
      link={{
        url: `${localizePath({
          ...props.pageContext,
          isPreview: false,
          slug: props.slug,
        })}`,
      }}
      to={props.slug}
    >
      <div className="news-card" key={props.currentItemId}>
        <div className="img-wrapper">
          <img src={props.image} alt="" />
        </div>
        <div className="content-wrapper">
          <Moment className="date" format="DD.MM.YYYY">
            {props.publishedAt}
          </Moment>
          <p className="description">{props.description}</p>
          <div className="read-more-wrapper">
            <p className="read-more">{props.readMore}</p>
            <RiArrowRightLine className="read-more-arrow" />
          </div>
        </div>
      </div>
    </CustomLink>
  </div>
);
NewsBlogCard.propTypes = {
  readMore: PropTypes.string.isRequired,
  publishedAt: PropTypes.string.isRequired,
  currentItemId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default NewsBlogCard;
