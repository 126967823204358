/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactMarkdown from 'react-markdown';
// import Slider from 'react-slick';
import PropTypes from 'prop-types';
// import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

// import wave from '../../../images/wave (1).svg';

// import useTeams from '../../../queries/teamsQueries';

import './ourTeam.scss';

const OurTeam = (props) => (
  // const [sliderRef, setSliderRef] = useState(null);
  // const [hoverImage, setHoverImage] = useState('image');
  // const teamsData = useTeams();
  // const langTeamsData = teamsData.filter(
  //   (item) => item.locale === props.pageContext.locale,
  // );
  // const settings = {
  //   dots: false,
  //   arrows: true,
  //   fade: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  <div className="main-team-wrapper mt-10 lg:mt-10">
    <div className="background-team container-wrapper">
      <div className="title-wrapper">
        <h2 className="custom-font-inter">{props.data.title}</h2>
      </div>
      <div className="offer-description custom-font-inter">
        <ReactMarkdown>
          {props.data.ourTeamDescription?.data?.ourTeamDescription}
        </ReactMarkdown>
      </div>

      {/* <Slider className="slider" ref={setSliderRef} {...settings}>
          {langTeamsData.map((item) => (
            <div className="main-wrapper" key={item.id}>
              <div className="image-container">
                <img
                  className={hoverImage}
                  alt=""
                  src={item.image.url}
                  onMouseEnter={() => setHoverImage('hover-img')}
                  onMouseOut={() => setHoverImage('image')}

                />
                <img className="wave" src={wave} alt="" />
                                <button
                  className="btn btn-left arrows"
                  type="submit"
                  onClick={sliderRef?.slickPrev}
                >
                  <BiLeftArrowAlt className="arrow" />
                </button>
                <button
                  className="btn btn-right arrows"
                  type="submit"
                  onClick={sliderRef?.slickNext}
                >
                  <BiRightArrowAlt className="arrow" />
                </button>
              </div>
              <div className="description-wrapper">
                <h3 className="title custom-font-inter">{`${item.firstName} ${item.lastName}`}</h3>
                <p className="description custom-font-inter">
                  {item.aboutText}
                </p>
                <h3 className="title subtitle">{`${props.data.aboutMemberTitle} ${item.firstName}`}</h3>
                <div className="info-data">
                  <p className="info-title custom-font-inter">
                    {`${props.data.roleTitle}: `}
                    <span className="description custom-font-inter">
                      {item.role}
                    </span>
                  </p>
                  <p className="info-title custom-font-inter">
                    {`${props.data.joinedTitle}: `}
                    <span className="description">{item.joinedSince}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
    </div>
  </div>
);
OurTeam.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    aboutMemberTitle: PropTypes.string.isRequired,
    // ourTeamDescription: PropTypes.string.isRequired,
    joinedTitle: PropTypes.string.isRequired,
    roleTitle: PropTypes.string.isRequired,
    // role: PropTypes.string.isRequired,
    // image: PropTypes.shape({
    // url: PropTypes.string.isRequired,
    // }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default OurTeam;
