/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

import wave from '../../../images/wave (1).svg';

import useProjects from '../../../queries/projectsQueries';

import './ourProjects.scss';

const OurProjects = (props) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [hoverImage, setHoverImage] = useState('image');

  const projectsData = useProjects();
  const langProjectsData = projectsData.filter(
    (item) => item.locale === props.pageContext.locale,
  );
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="background container-wrapper">
        <div className="title-wrapper">
          <h2 className="custom-font-inter">{props.data.title}</h2>
        </div>
        <Slider className="slider" ref={setSliderRef} {...settings}>
          {langProjectsData.map((item) => (
            <div className="main-wrapper" key={item.id}>
              <div className="image-container">
                <img
                  className={hoverImage}
                  alt=""
                  src={item.image.url}
                  onMouseEnter={() => setHoverImage('hover-img')}
                  onMouseOut={() => setHoverImage('image')}
                />
                <img className="wave" src={wave} alt="" />
                <button
                  className="btn btn-left arrows"
                  type="submit"
                  onClick={sliderRef?.slickPrev}
                >
                  <BiLeftArrowAlt className="arrow" />
                </button>
                <button
                  className="btn btn-right arrows"
                  type="submit"
                  onClick={sliderRef?.slickNext}
                >
                  <BiRightArrowAlt className="arrow" />
                </button>
              </div>
              <div className="description-wrapper">
                <h3 className="title subtitle custom-font-inter">
                  {props.data.projectDetailsTitle}
                </h3>
                <div className="info-data">
                  <p className="info-title custom-font-inter">
                    {`${props.data.descriptionTitle}: `}
                    <span className="description custom-font-inter">
                      {item.title}
                    </span>
                  </p>
                  <p className="info-title custom-font-inter">
                    {`${props.data.numberOfObjectsTitle}: `}
                    <span className="description custom-font-inter">
                      {item.numberOfObjects}
                    </span>
                  </p>
                  <p className="info-title custom-font-inter">
                    {`${props.data.surfaceAreaTitle}: `}
                    <span className="description custom-font-inter">
                      {item.surfaceArea}
                    </span>
                  </p>
                  <p className="info-title custom-font-inter">
                    {`${props.data.buildingTypeTitle}: `}
                    <span className="description custom-font-inter">
                      {item.buildingType}
                    </span>
                  </p>
                  <div className="info-title custom-font-inter our-role">
                    {`${props.data.ourRoleTitle}: `}
                    <span>
                      <ReactMarkdown className="description our-role-content custom-font-inter">
                        {item.ourRole.data.ourRole}
                      </ReactMarkdown>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

OurProjects.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    descriptionTitle: PropTypes.string.isRequired,
    locationTitle: PropTypes.string.isRequired,
    clientTitle: PropTypes.string.isRequired,
    surfaceAreaTitle: PropTypes.string.isRequired,
    ourRoleTitle: PropTypes.string.isRequired,
    numberOfObjectsTitle: PropTypes.string.isRequired,
    buildingTypeTitle: PropTypes.string.isRequired,
    projectDetailsTitle: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default OurProjects;
