import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import PropTypes from 'prop-types';
import AOS from 'aos';

import 'aos/dist/aos.css';

import './ourOffer.scss';

const OurOffer = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="company-offer-values-container">
      <div className="main-offer-container container-wrapper">
        <div className="offer-title-container ">
          <h1 className="title custom-font-inter">{props.data.title}</h1>
        </div>
        <div className="offer-description custom-font-inter">
          <ReactMarkdown className="paragraph-text custom-font-inter mark-down-content">
            {props.data.ourOfferDescription.data.ourOfferDescription &&
              props.data.ourOfferDescription.data.ourOfferDescription}
          </ReactMarkdown>
        </div>
        <div className="offer-cards-container">
          {props.data.ourOfferContainer.map((item) => (
            <div className="offer-cards" key={item.id}>
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="img-container">
                  <img src={item.imageUrl} alt="" />
                </div>
                <div className="content-container">
                  <h3 className="custom-font-inter">{item.description}</h3>
                  <ReactMarkdown className="paragraph-text custom-font-inter mark-down-content">
                    {item.content?.data?.content}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

OurOffer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    ourOfferDescription: PropTypes.shape({
      data: PropTypes.shape({
        ourOfferDescription: PropTypes.string.isRequired,
      }),
    }),
    ourOfferContainer: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        content: PropTypes.shape({
          data: PropTypes.shape({
            content: PropTypes.string.isRequired,
          }),
        }),
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default OurOffer;
