import React from 'react';
import PropTypes from 'prop-types';
import Flip from 'react-reveal/Fade';

import './hero.scss';

const HeroSection = (props) => (
  <div>
    <div className="hero-container">
      <div className="image-container">
        <img src={props.data.image.url} alt="" />
      </div>
      <Flip cascade delay={500}>
        <div className="container-wrapper content-wrapper">
          <h2 className="custom-font-inter">{props.data.title}</h2>
          <p className="custom-font-inter">{props.data.description}</p>
        </div>
      </Flip>
    </div>
    {/* Mobile */}
    <div className="hero-container-mobile">
      <div className="image-container-mobile">
        <img src={props.data.imageMobile.url} alt="" />
      </div>
      <Flip cascade delay={500}>
        <div className="content-wrapper-mobile custom-font-inter container-wrapper">
          <h2 className="mobile-title custom-font-inter">{props.data.title}</h2>
          <p className="mobile-description custom-font-inter">
            {props.data.description}
          </p>
        </div>
      </Flip>
    </div>
  </div>
);
HeroSection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    imageMobile: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default HeroSection;
