import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import AOS from 'aos';

import '../OurOffer/ourOffer.scss';

const CompanyValues = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="company-offer-values-container">
      <div className="main-offer-container container-wrapper">
        <div className="offer-title-container ">
          <h1 className="title section-title custom-font-inter">
            {props.data.title}
          </h1>
        </div>
        <div className="offer-cards-container">
          {props.data.ourValues.map((item) => (
            <div data-aos="fade-up" data-aos-duration="1000" key={item.id}>
              <div className="offer-cards">
                <div className="img-container">
                  <img src={item.imageUrl} alt="" />
                </div>
                <div className="content-container">
                  <h3 className="custom-font-inter">{item.description}</h3>
                  <ReactMarkdown className="paragraph-text custom-font-inter mark-down-content">
                    {item.content?.data?.content}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CompanyValues.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    ourValues: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        content: PropTypes.shape({
          data: PropTypes.shape({
            content: PropTypes.string.isRequired,
          }),
        }),
        imageUrl: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default CompanyValues;
