import React from 'react';
import PropTypes from 'prop-types';

import BriefCase from '../../../images/Vector (21).svg';
import './contact.scss';

const Modal = (props) => {
  const { messageSent, messageTextSent } = props;
  return (
    <div className="modal-container">
      <div className="modal-content-wrapper">
        <div className="modal-img-container">
          <img src={BriefCase} alt="" />
        </div>
        <h3 className="modal-title">{messageSent}</h3>
        <p className="modal-text">{messageTextSent}</p>
      </div>
    </div>
  );
};

Modal.propTypes = {
  messageSent: PropTypes.string.isRequired,
  messageTextSent: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Modal;
