import { graphql, useStaticQuery } from 'gatsby';

const useBlogs = () => {
  const {
    allStrapiBlog: { nodes: blogs },
  } = useStaticQuery(graphql`
    {
      allStrapiBlog(sort: {order: DESC, fields: createdAt}) {
        nodes {
          createdAt
          publishedAt
          authorImage {
            url
          }
          authorName
          authorPosition
          construction
          slug
          contentFirst {
            data {
              contentFirst
            }
          }
          contentSecond {
            data {
              contentSecond
            }
          }
          date
          description
          categories
          image {
            url
          }
          locale
          newsOrBlog
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          subtitleFirst
          subtitleSecond
          timeRead
          title
          backToNewsAndBlog
          backToNewsAndBlogSlug
          categories
        }
      }
    }
  `);
  return blogs;
};

export default useBlogs;
