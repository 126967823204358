import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import '../../../styles/global.scss';
import './aboutUs.scss';

const AboutUs = (props) => (
  <div className="about-us-main-wrapper">
    <div className="about-us-container container-wrapper">
      <h2 className="custom-font-inter">{props.data.title}</h2>
      <ReactMarkdown className="paragraph-text custom-font-inter">
        {props.data.content.data.content}
      </ReactMarkdown>
    </div>
  </div>
);

AboutUs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default AboutUs;
