import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@trendyol-js/react-carousel';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

import AOS from 'aos';

import 'aos/dist/aos.css';

import useProjects from '../../../queries/projectsQueries';

import CustomCard from '../../CustomProjectCard';

import './projectSlider.scss';

const ProjectSlider = (props) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [isMobile, setIsMobile] = useState(true);
  const { pageContext } = props;
  const projectsData = useProjects();
  const langProjectsData = projectsData.filter(
    (item) => item.locale === props.pageContext.locale,
  );
  const leftBtn = (
    <button
      className="btn btn-left arrows"
      type="submit"
      onClick={sliderRef?.slickPrev}
    >
      <BiLeftArrowAlt className="arrow" />
    </button>
  );
  const rightBtn = (
    <button
      className="btn btn-right arrows"
      type="submit"
      onClick={sliderRef?.slickNext}
    >
      <BiRightArrowAlt className="arrow" />
    </button>
  );

  useEffect(() => {
    if (window.innerWidth >= 768) setIsMobile(false);
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="main-projects-container container-wrapper">
      <div className="title-wrapper">
        <h2 className="custom-font-inter">{props.data.title}</h2>
      </div>
      <div data-aos="fade-up">
        <Carousel
          show={!isMobile ? 3 : 1}
          slide={1}
          responsive
          swiping
          ref={setSliderRef}
          leftArrow={leftBtn}
          rightArrow={rightBtn}
          className="project-card-container relative"
        >
          {langProjectsData.map((item) => (
            <CustomCard
              key={item.id}
              pageContext={pageContext}
              projectKey={item.id}
              image={item.image.url}
              description={item.shortDescription}
              title={item.title}
              slug={item.slug}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

ProjectSlider.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    projectContainer: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default ProjectSlider;
