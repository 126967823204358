import { graphql, useStaticQuery } from 'gatsby';

const useNews = () => {
  const {
    allStrapiNew: { nodes: news },
  } = useStaticQuery(graphql`
    {
      allStrapiNew(sort: {order: DESC, fields: createdAt}) {
        nodes {
          createdAt
          publishedAt
          authorImage {
            url
          }
          authorName
          authorPosition
          construction
          description
          slug
          contentFirst {
            data {
              contentFirst
            }
          }
          contentSecond {
            data {
              contentSecond
            }
          }
          date
          id
          image {
            url
          }
          locale
          newsOrBlog
          subtitleFirst
          subtitleSecond
          backToNewsAndBlog
          backToNewsAndBlogSlug
          categories
          timeRead
          title
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
        }
      }
    }
  `);
  return news;
};

export default useNews;
