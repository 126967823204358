import React from 'react';
import PropTypes from 'prop-types';
import CustomNewsBlogCard from '../../CustomNewsBlogCard/index';

const Items = (props) => {
  const { currentItems, readMore, pageContext } = props;
  return (
    <div>
      {currentItems
        && currentItems.map((item) => (
          <CustomNewsBlogCard
            image={item.image.url}
            currentItemId={item.id}
            date={item.date}
            description={item.description}
            readMore={readMore}
            slug={item.slug}
            title={item.title}
            pageContext={pageContext}
            createdAt={item.createdAt}
          />
        ))}
    </div>
  );
};
Items.propTypes = {
  readMore: PropTypes.string.isRequired,
  pageContext: PropTypes.string.isRequired,
  currentItems: PropTypes.string.isRequired,
};
export default Items;
