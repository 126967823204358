/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { MdEmail, MdLocationOn } from 'react-icons/md';
import { HiPhone } from 'react-icons/hi';

import Modal from './modal';

import './contact.scss';

const schema = yup.object({
  email: yup.string().email('Enter valid Email').required('Email required'),
});

const Contact = (props) => {
  const [successText, setSuccessText] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendEmail = () => {
    emailjs.sendForm(
      'service_481qffn',
      'template_pdfbb0s',
      '#contact-form',
      'o_Ms6QwyzX2GwJSPM',
    );
    setSuccessText(true);
    reset();
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return successText ? (
    <Modal
      messageSent={props.data.messageSent}
      messageTextSent={props.data.messageTextSent}
    />
  ) : (
    <div className="main-contact-wrapper contact-container-wrapper">
      <div className="title-wrapper">
        <h2 className="custom-font-inter">{props.data.contactTitle}</h2>
      </div>
      <div className="contact-wrapper mb-8 md:mb-0 md:flex">
        <div className="contact-right">
          <div className="form-title-wrapper">
            <h4 className="form-title custom-font-inter">
              {props.data.contactFormTitle}
            </h4>
          </div>
          <form
            id="contact-form"
            className="form-container mb-8"
            onSubmit={handleSubmit(sendEmail)}
          >
            <div className="form-group my-6">
              <input
                type="text"
                name="name"
                placeholder="Name"
                aria-label="name"
                required
              />
              <label
                className="form-label"
                htmlFor="exampleFormControlInput1"
                name="name"
              >
                {props.data.placeholderName}
              </label>
            </div>
            <div className="form-group my-6">
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                required
                {...register('email')}
              />
              <label className="form-label" htmlFor="email">
                {props.data.placeholderMail}
              </label>
              {errors?.email?.message && (
                <span className="error-message">{errors?.email?.message}</span>
              )}
            </div>

            <div className="form-group my-6">
              <input
                type="number"
                name="phone"
                placeholder="Phone Number"
                required
              />
              <label className="form-label" htmlFor="phone">
                {props.data.placeholderPhoneNumber}
              </label>
            </div>
            <div className="form-group my-6">
              <textarea
                className="custom-scrollbar"
                type="text"
                name="message"
                rows="3"
                cols="30"
                maxLength="195"
                placeholder="Your message"
              />

              <label className="form-label" htmlFor="message" required>
                {props.data.placeholderMessage}
              </label>
            </div>
            <div className="button-wrapper">
              <button
                onClick={handleToggle}
                type="submit"
                className={successText ? 'modal-container' : 'button'}
              >
                {props.data.buttonSend}
              </button>
            </div>
          </form>
        </div>
        <div className="contact-left">
          <div className="contact-left-wrapper">
            <h4 className="custom-font-inter">
              {props.data.contactInformationTitle}
            </h4>
            <div className="contact-info-wrapper mb-6 flex">
              <MdLocationOn className="location-icon" />
              <p className="">{props.data.address}</p>
            </div>
            <div className="contact-info-wrapper flex">
              <MdEmail className="contact-icons" />
              <a className="" href={`mailto:${props.data.mail}`}>
                {props.data.mail}
              </a>
            </div>
            <br />
            <div className="contact-info-wrapper flex">
              <HiPhone className="contact-icons" />
              <a className="" href={`tel:${props.data.phone1}`}>
                {props.data.phone1}
              </a>
            </div>
            <br />
            <div className="contact-info-wrapper mb-6 flex" href="">
              <HiPhone className="contact-icons" />
              <a className="" href={`tel:${props.data.phone2}`}>
                {props.data.phone2}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  data: PropTypes.shape({
    buttonSend: PropTypes.string.isRequired,
    placeholderPhoneNumber: PropTypes.string.isRequired,
    placeholderName: PropTypes.string.isRequired,
    phone2: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    contactInformationTitle: PropTypes.string.isRequired,
    buttonSent: PropTypes.string.isRequired,
    placeholderMessage: PropTypes.string.isRequired,
    placeholderMail: PropTypes.string.isRequired,
    contactFormTitle: PropTypes.string.isRequired,
    phone1: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    contactTitle: PropTypes.string.isRequired,
    validEmail: PropTypes.string.isRequired,
    requiredEmail: PropTypes.string.isRequired,
    messageSent: PropTypes.string.isRequired,
    messageTextSent: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Contact;
