import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import './impressum.scss';

const Impressum = (props) => (
  <div className="impressum-container">
    <div className=" main-impressum-container container-wrapper">
      <div className="title-wrapper">
        <h1 className="impressum-title section-title custom-font-inter">
          {props.data.title}
        </h1>
      </div>
      <div className="impressum-sub-title">
        <h2>{props.data.founder}</h2>
        <h2>{props.data.founderTitle}</h2>
      </div>
      <div className="impressum-contact">
        <div className="info-wrapper">
          <p>
            {`${props.data.addressTitle}: `}
            <span>{props.data.address}</span>
          </p>
          <a href={`tel: ${props.data.phone}`}>
            {`${props.data.phoneTitle}: `}
            <span>{props.data.phone}</span>
          </a>
        </div>
        <div className="info-wrapper">
          <a href={`mailto:${props.data.email}`}>
            {`${props.data.emailTitle}: `}
            <span>{props.data.email}</span>
          </a>
          <br />
          <a className="web-link" href={`tel: ${props.data.web}`}>
            {`${props.data.webTitle}: `}
            <span>{props.data.web}</span>
          </a>
          <p>
            {`${props.data.ustTitle}: `}
            <span>{props.data.ustNr}</span>
          </p>
        </div>
      </div>
      <div className="impressum-contact impressum-additional-info">
        <p>
          {`${props.data.memberTitle}: `}
          <span>{props.data.founder}</span>
        </p>
        <p>{props.data.memberContent}</p>
        <p>
          {`${props.data.memberNrTitle}: `}
          <span>{props.data.memberNrContent}</span>
        </p>
      </div>
      <div className="impressum-sub-title impressum-sub-title-2">
        <h2>{props.data.mainContentTitle}</h2>
      </div>
      <div className="content-wrapper">
        <ReactMarkdown className="impressum-content">{props.data.content.data.content}</ReactMarkdown>
      </div>
    </div>
  </div>
);

Impressum.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    founder: PropTypes.string.isRequired,
    phoneTitle: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    addressTitle: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    web: PropTypes.string.isRequired,
    webTitle: PropTypes.string.isRequired,
    emailTitle: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    managerPhone: PropTypes.string.isRequired,
    managerPhoneTitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    privacyPolicyTitle: PropTypes.string.isRequired,
    memberContent: PropTypes.string.isRequired,
    memberTitle: PropTypes.string.isRequired,
    contentTitle: PropTypes.string.isRequired,
    memberNrContent: PropTypes.string.isRequired,
    memberNrTitle: PropTypes.string.isRequired,
    ustNr: PropTypes.string.isRequired,
    mainContentTitle: PropTypes.string.isRequired,
    ustTitle: PropTypes.string.isRequired,
    founderTitle: PropTypes.string.isRequired,
    courtTitle: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Impressum;
